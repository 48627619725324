export const CONFIGURATION_TYPES = Object.freeze({
  EMPTY: {
    name: 'empty',
    title: 'Empty',
    icon: 'file',
    description: 'Create a new vehicle configuration from scratch.'
  },
  IMPORT_FILE: {
    name: 'importFile',
    title: 'Import file',
    icon: 'file-upload',
    description: `Create a new vehicle configuration based on an
    existing configuration by importing a JSON file with the C4S structure.`
  }
})

export const EMPTY_CONFIGURATION_TEMPLATE = Object.freeze({
  name: '',
  project: '',
  c4sCode: '',
  productModel: {},
  planningPeriodRange: { from: '' },
  description: '',
  selectedChoices: [],
  windChillData: { variantSpecNumber: '' }
})

export const PAC_SAVE_TYPES = Object.freeze({
  NONE: 'none',
  CURRENT: 'current',
  NEW: 'new'
})

export const PAC_CONFIGS_TABS = Object.freeze({
  ALL: { key: 'options', value: 'All options' },
  INVALID: { key: 'invalidOptions', value: 'Invalid results' }
})

export const USER_PERMISSION_OPTIONS = Object.freeze({
  OPTIONS: ['Everyone', 'Selected users'],
  EVERYONE: 'Everyone',
  SELECTED_USERS: 'Selected users'
})

// editorconfig-checker-disable
// eslint-disable-next-line
export const EXPLANATION_RULE_WINDCHILL_LINK = 'https://mucwtprod.mn-man.biz:40049/Windchill/app/#ptc1/tcomp/infoPage?ContainerOid=OR%3Awt.inf.library.WTLibrary%3A104026&oid=OR%3Acom.ptc.windchill.option.model.ChoiceRule%3A{ruleId}&u8=1'
// editorconfig-checker-enable

// eslint-disable-next-line max-len,vue/max-len
export const NO_RECOVERY_SUGGESTIONS_AVAILABLE_MESSAGE = 'Error: This combination is invalid, but no recovery suggestions were found.'
